import { NextPage } from "next";
import React from "react";
import { Layout1 } from "../layout/layout1";

export const IndexPage: NextPage = () => {
    return (
        <Layout1>
            <h2>404 - Not found</h2>
        </Layout1>
    )
};


export default IndexPage
